import React from 'react';
import { FaPhone, FaGift, FaTrophy } from 'react-icons/fa'; // Icons for the steps

const Step = ({ icon, title, description }) => (
  <div className="flex flex-col items-center">
    <div className="flex items-center justify-center w-12 h-12 bg-gray-100 border-2 border-gray-300 rounded-full">
      {icon}
    </div>
    <h3 className="mt-2 text-sm font-semibold text-center text-gray-800">{title}</h3>
    <p className="text-[8px] text-center text-gray-500">{description}</p>
  </div>
);

const Steps = () => {
  return (
    <div className="flex items-center justify-center p-4 my-6 space-x-2">
      {/* Step 1 */}
      <Step
        icon={<FaPhone className="w-6 h-6 text-black" />}
        title="Link Phone Number"
        description="Quickly link your phone number to get started."
      />
      
      {/* Connector Line */}
      <div className="w-4 h-1 bg-gray-400"></div>
      
      {/* Step 2 */}
      <Step
        icon={<FaGift className="w-6 h-6 text-black" />}
        title="Check for Bonuses"
        description="Discover bonuses and offers just for you."
      />
      
      {/* Connector Line */}
      <div className="w-4 h-1 bg-gray-400"></div>

      {/* Step 3 */}
      <Step
        icon={<FaTrophy className="w-6 h-6 text-black" />}
        title="Claim Your Perks"
        description="Claim your perks and start enjoying rewards."
      />
    </div>
  );
};

export default Steps;
