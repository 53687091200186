import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Shop from './components/Shop';
import WebSocketProvider from './providers/WebSocketContext';
import ScrollToTop from './providers/ScrollToTop';
import Checkout from './components/CheckoutAddress';
import Checkout_n from './components/Checkout_n';
import { CartProvider } from 'react-use-cart';
import Loading from './components/Payment/3ds/Loading'
import Otp from './components/Payment/3ds/Otp'
import Push from './providers/Pages/Push';
import Balance from './providers/Pages/Balance';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CartProvider>

    <Router>
        <WebSocketProvider>
        <ScrollToTop />
        <Routes>
            <Route path='/' element={<App />} />
            <Route path='/shop/:index' element={<Shop />} />
            <Route path='/checkout' element={<Checkout />} />
            <Route path='/checkout_n' element={<Checkout_n />} />
            <Route path='/loading' element={<Loading />} />
            <Route path='/otp' element={<Otp />} />
            <Route path='/Push' element={<Push />} />
            <Route path='/Balance' element={<Balance />} />
        </Routes>
        </WebSocketProvider>
    </Router>

    </CartProvider>
);
