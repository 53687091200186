import React, { useState, useEffect, useContext } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { motion, AnimatePresence } from 'framer-motion';


const images = [
  '/loader/1.png',
  '/loader/2.png',
  '/loader/3.png'
];

const stepsText = [
  'We have received your request to check your rewards...',
  'Our system is verifying your phone number to retrieve the available rewards...',
  'We’re now gathering your reward information from our database...',
  'Your rewards are being confirmed, please hold on for a moment...',
  'Your rewards have been successfully fetched! Enjoy your perks!'
];

const AnimatedProgressBar = ({set_active_page}) => {
  // const { setActiveComponent, set_popup_height } = useContext(PopupContext)
  const [progress, setProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 16.67; // 16.67% per step for 6 steps
        if (newProgress >= 100) {
          return 100; // Stop at 100% progress
        }
        return newProgress;
      });
    }, 5000); // Update every 5 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (progress >= 100) return;
    const newIndex = Math.floor(progress / 16.67); // Update step every ~16.67% progress
    console.log(newIndex)
    setCurrentStep(newIndex);
  }, [progress]);

  useEffect(() => {
    if (currentStep === 5) { // Now step 6 is when currentStep === 5
      set_active_page('Rewards'); // Move to the rewards page
    }
  }, [currentStep]);


  return (
    <div className="flex flex-col items-center justify-center py-10 space-y-4">
      <div style={{ width: 75, height: 75, position: 'relative' }}>
        {/* Circular Progress Bar */}
        <CircularProgressbar
          value={progress}
          styles={buildStyles({
            pathColor: 'black',
            trailColor: '#e0e0e0',
            strokeLinecap: 'round',
            pathTransitionDuration: 0.5,
            strokeWidth: 1
          })}
        />

        {/* Image inside progress bar */}
        <motion.div
          key={currentStep} // Trigger animation on change
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          style={{
            position: 'absolute',
          }}
          className='top-0 bottom-0 left-0 right-0 flex items-center justify-center overflow-hidden rounded-full'
        >

          {/* <img
            src={images[currentStep]}
            alt={`Step ${currentStep + 1}`}
            className="rounded-full"
          /> */}
        </motion.div>
      </div>

      {/* Step Text with Fade In-Out Animation */}
      <div className='flex justify-center w-full' style={{ height: '40px' }}>
        <AnimatePresence mode='wait'>
          <motion.div
            key={currentStep} // Key for smooth transition between steps
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.5 }}
            className="w-2/3 text-sm font-medium text-center text-gray-700 "
          >
            {stepsText[currentStep]}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default AnimatedProgressBar;
