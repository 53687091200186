import React from 'react'

const HeroSection = () => {
  return (
    <div className='relative flex items-center w-full'>
        <img className='w-full h-full object-fit' src="/about-banner.original.webp" alt="" />
        <span className='absolute pb-2 text-xl font-extrabold border-b border-black left-4'>Why We’re Giving Back</span>
    </div>
  )
}

export default HeroSection