import React, { useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay"; // Not mandatory but good to include

// Import the modules explicitly
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { useCart } from 'react-use-cart';


const Shop = () => {

  const [submit_btn_content, set_submit_btn_content] = useState('Continue')

    const {
        addItem,
        removeItem,
        updateItemQuantity,
        items,
        isEmpty,
        totalItems,
        cartTotal,
        emptyCart,
      } = useCart()
      
      const navigate = useNavigate()
    const products =[
        {
            id:3,
            name: 'Sony PlayStation 5 Pro Console',
            brand: 'Sony',
            details: ['Features including GPU upgrade, advanced ray tracing, and PlayStation Spectral Super Resolution take players to new heights.',
                'Upgraded GPU: With PS5 Pro, we are upgrading to a GPU that has 67% more Compute Units than the current PS5 console and 28% faster memory. Overall, this enables up to 45% faster rendering for gameplay, making the experience much smoother.',
                "Advanced Ray Tracing: We've added even more powerful ray tracing that provides more dynamic reflection and refraction of light. This allows the rays to be cast at double, and at times triple, the speeds of the current PS5 console.",
                "AI-Driven Upscaling: We're also introducing PlayStation Spectral Super Resolution, an AI-driven upscaling that uses a machine learning-based technology to provide super sharp image clarity by adding an extraordinary amount of detail."],
                price_stock: 247,
                price_points: 83,
                imgs: ['/shop/playstation/549869-01.webp','/shop/playstation/549869-02.webp','/shop/playstation/549869-03.webp','/shop/playstation/549869-04.webp']
            },
            {
            id:1,
            name: 'Apple iPhone 15 Pro 6.1-inch 128GB 5G Blue',
            brand: 'Apple',
            details: ['Display: 6.1 inch Super Retina XDR OLED Display, 2556 x 1179 Pixels',
                'Processor: A17 Pro chip',
                'Memory: 128GB',
                'Back Camera: 48MP (f/1.78) + 12MP (f/2.2) + 12MP (f/1.78)',
                'Front Camera: 12MP (f/1.9)',
                'CPU: New 6‑core CPU with 2 performance and 4 efficiency cores',
                'OS: iOS 17',
                'Battery: Non Removable',
                'Sensors: Face ID, LiDAR Scanner, Barometer, High dynamic range gyro, High-g accelerometer, Proximity sensor, Dual ambient light sensors'],
                price_stock: 372,
            price_points: 117,
            imgs: ['/shop/iphone/653826-01.webp','/shop/iphone/653826-02.webp','/shop/iphone/653826-03.webp','/shop/iphone/653826-04.webp','/shop/iphone/653826-05.webp']
        },
        {
            id:2,
            name: 'Samsung Galaxy S23 Ultra 256GB Phone - Phantom Black',
            brand: 'Samsung',
            details: ['Display:6.8" Edge Quad HD+',
                '1750 nits',
                'Refresh Rate: 120Hz',
                'Processor: Snapdragon 8 Gen 2',
                'Rear Camera: 12MP Ultra Wide + 200MP Wide-angle + 10MP 2xTelephoto',
                'Front Camera: 12MP',
                'Armor Aluminum frame',
                'Corning Gorilla Glass Victus',
                'IP68 Water Resistance',
                'S-Pen Compatible'],
            price_stock: 417,
            price_points: 132,
            imgs: ['/shop/samsung/541129-00.webp','/shop/samsung/541129-01.webp','/shop/samsung/541129-02.webp','/shop/samsung/541129-03.webp','/shop/samsung/541129-04.webp',]
        },
    ]
    const {index} = useParams()

    const [product_show, setproduct_show] = useState(products[index])



    const hadnleAddItem = ()=>{
        try{
            addItem({
                id: product_show.id,
                name: product_show.name,
                price_stock: product_show.price_stock,
                price: product_show.price_points,
                img: product_show.imgs[0],
            })
        }catch(e){
            console.log(e)
        }
    }
  return (
    <div className='flex flex-col'>
        <Header />

        <div className="fixed bottom-0 z-50 w-full p-4 pb-8 bg-white">
            <button onClick={()=>{hadnleAddItem();set_submit_btn_content(<div className="inputLoader"></div>); setTimeout(()=>{
                navigate('/checkout')
            },3000)}} className='w-full py-2 font-semibold text-white bg-[#d12b8a] rounded-lg'>{submit_btn_content}</button>
        </div>
        <div className="w-full">
        <div className="w-full max-w-4xl mx-auto">
        <Link to={'/'}  className="flex items-center p-4">
        <div className="flex items-center justify-center w-10 h-10 rounded-full shadow">
        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M21 11L6.414 11 11.707 5.707 10.293 4.293 2.586 12 10.293 19.707 11.707 18.293 6.414 13 21 13z"></path></svg>
        </div>
        <span className="ml-2 text-lg font-semibold">Back</span>
      </Link>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000 }}
        loop={true}
        className="mySwiper"
      >
        {product_show.imgs.map((src, index) => (
          <SwiperSlide key={index}>
            <img
              src={src}
              alt={`Slide ${index + 1}`}
              className="object-cover w-full h-auto p-12"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
    <div className="flex flex-col w-full p-4">
        <span className='uppercase text-neutral-500'>{product_show.brand}</span>
        <span className='text-3xl text-[#2e2e2e] font-semibold'>{product_show.name}</span>
        <div className="flex items-center mt-5 ">
            
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 0C7.34772 0 4.80414 1.05376 2.92907 2.92907C1.05376 4.80437 0 7.34795 0 10C0 12.652 1.05376 15.1959 2.92907 17.0709C4.80438 18.9462 7.34795 20 10 20C12.652 20 15.1959 18.9462 17.0709 17.0709C18.9462 15.1956 20 12.652 20 10C20 7.34795 18.9462 4.80414 17.0709 2.92907C15.1956 1.05376 12.652 0 10 0V0ZM10 18.0647C7.86121 18.0647 5.80997 17.2151 4.2974 15.7027C2.78506 14.1901 1.93539 12.1389 1.93539 10.0001C1.93539 7.86132 2.78506 5.81009 4.2974 4.29751C5.80997 2.78517 7.86121 1.93551 10 1.93551C12.1388 1.93551 14.19 2.78517 15.7026 4.29751C17.2149 5.81009 18.0646 7.86132 18.0646 10.0001C18.0646 12.1389 17.2149 14.1901 15.7026 15.7027C14.19 17.2151 12.1388 18.0647 10 18.0647Z" fill="#028666"/>
<path d="M9.03252 11.2128L6.8132 8.99345L5.44556 10.3611L8.3487 13.2642V13.2645C8.53006 13.4456 8.77601 13.5475 9.03252 13.5475C9.28903 13.5475 9.53499 13.4456 9.71634 13.2645L14.8778 8.10299L13.5102 6.73535L9.03252 11.2128Z" fill="#028666"/>
</svg>

<span className='text-[#028666] ml-2'>in stock</span>
        </div>
        <div className="flex flex-col w-full bg-white rounded-lg">

<div className="flex items-end justify-between p-2">
  <div className=""></div>
  <div
    className={`relative px-2 py-2 rounded-lg bg-pink-500 text-white`}
  >
    <div className="z-40 flex items-center">

      <span className="z-40 font-semibold ">
        17850 <span className="text-xs ">points</span>
      </span>
    </div>
    <div
      className={`before:border-t-pink-500 absolute bottom-0 right-0 w-0 h-0 before:content-[''] before:absolute before:-bottom-[8px] before:right-[0px] before:border-t-[20px] before:border-l-[20px] before:border-transparent `}
    ></div>
  </div>
</div>
<div className="w-full px-2">
  <div className="relative w-full h-2 overflow-hidden rounded">
    <div
      className={`absolute inset-0 h-full bg-duotone-stripes animate-move-right-slow`}
    ></div>
  </div>
</div>

</div>
<div className="flex flex-col w-full px-2 mt-4">
    <span className='line-through text-neutral-500'>KD {product_show.price_stock.toFixed(3)}</span>
    <span className='text-3xl font-bold text-pink-500'>KD {product_show.price_points.toFixed(3)}</span>
</div>
<div className="w-full my-5 border-b"></div>
<span className='text-xl font-semibold text-[#2e2e2e] mb-6'>Device specifications</span>
<ul className='flex flex-col pl-4 mb-6 list-disc gap-y-4'>
    {product_show.details.map(detail=>(
        <li className='text-sm text-neutral-500'>{detail}</li>
    ))}
</ul>
    </div>
        </div>
        <Footer />
    </div>
  )
}

export default Shop