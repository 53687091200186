import React, { useState, useEffect } from 'react';
import FlipNumbers from 'react-flip-numbers';

const DrumSpinningNumbers = () => {
  const [number, setNumber] = useState(0);

  // Simulate number update every 3 seconds


  return (
    <div className="flex items-center ">
      <FlipNumbers
        height={40}
        width={30}
        color="black"
        background="white"
        play
        perspective={1000}
        durationSeconds={5000} // Make the animation last longer (5 seconds)
        numbers={number.toString().padStart(5, '17850')} // Ensure it displays two digits
      />
    </div>
  );
};

export default DrumSpinningNumbers;
