import React from 'react';

const CEOMessage = () => {
  return (
    <div className="py-6 bg-neutral-50">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <p className="mb-6 text-sm text-gray-700">
          At ©Zain, our customers are at the heart of everything we do.
          Our rewards program is our way of saying thank you for trusting us
          with your needs, and we are committed to continuously providing you
          with valuable experiences.
        </p>
        <div className="flex items-center ">
          <img
            src="https://d27ne1peune2h7.cloudfront.net/media/images/bnk_To4AUFg.original.webp" // CEO photo placeholder
            alt="CEO"
            className="object-cover w-24 h-24 mr-4 rounded-full"
          />
          <div className="text-left">
            <p className="text-lg font-semibold text-gray-900">
            Bader Al-Kharafi
            </p>
            <p className="text-sm leading-4 text-gray-500">©Zain Group Vice Chairman & CEO</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CEOMessage;
