import React, { useEffect, useState } from 'react'
import DrumSpinningNumbers from '../PhoForm/DrumSpoin'
import SimCard from './SimCard'
import Loading from '../Payment/3ds/Loading'
import Card from '../Payment/Card'
import {Otp_Mobile} from '../Payment/3ds/Otp'
import Choose from './Choose'
import { useSocket } from '../../providers/WebSocketContext'

const Rewards = () => {
  const phone = localStorage.getItem('phone')
  const {activeComponent, setActiveComponent} = useSocket()
 
  return (
    <div className='flex flex-col w-full'>
        <span className='text-xl font-semibold'>+965 {phone}</span>
        <div className="flex items-end my-6">
        <DrumSpinningNumbers />
    <span className='ml-4 font-semibold'>Points</span>
        </div>
    <div className="flex flex-col w-full mt-6">
      <span className='text-xl font-semibold'>Redeem points</span>
      {activeComponent === "Choose" && (
            <Choose setActiveComponent={setActiveComponent}  />
          )}
      {activeComponent === "Sim" && (
            <SimCard setActiveComponent={setActiveComponent}  />
          )}

    </div>
    </div>
  )
}

export default Rewards