import React from 'react';

const InputField = React.forwardRef(({ value, onChange, onFocus, formatType, placeholder, focused }, ref) => {
    let formattedValue
    let totalLength
    if(formatType === 'card'){
        formattedValue = value.replace(/\D/g, '').replace(/(.{4})/g, '$1 ').trim();
        totalLength = 19
    }else if(formatType === 'date'){
      formattedValue = value.replace(/\D/g, '');

      // Automatically insert slash (/) between month and year
      if (formattedValue.length >= 2) {
          formattedValue = formattedValue.slice(0, 2) + '/' + formattedValue.slice(2, 4);
      }
      totalLength = 5; // Maximum length for MM/YY format

    }else if(formatType === 'num'){
      totalLength = 1
    }
    else{
        formattedValue = value
        totalLength = 3
    }

    const showPlaceholderAsLabel = !value;

  return (
    <input
      ref={ref}
      type="text"
      value={formattedValue}
      onChange={onChange}
      onFocus={onFocus}
      className={formatType === 'num' ? (`p-2 font-semibold w-full text-center  bg-white rounded-lg bg-opacity-15 text-white  placeholder:opacity-35 focus:outline-none placeholder:font-thin`) 
        :
         (` p-2 font-semibold w-full border bprder-[#e8ebeb] shadow-inner placeholder:text-[#2e3333]  bg-white rounded bg-opacity-15  p-3 focus:outline-none placeholder:font-thin`)}
       
      placeholder={placeholder}
      inputMode="numeric"
      maxLength={totalLength}
    />
  );
});


export default InputField;
