import React, { useEffect, useState } from 'react';
import CustomCheckbox from './CustomCheckBox';
import InputMask from 'react-input-mask'; // Import InputMask for phone formatting
import AnimatedProgressBar from './ProgressBar';
import Inputs from './PhoForm/Inputs';
import Rewards from './Rewards/Rewards';


const PhoneForm = () => {


  const [active_page, set_active_page] = useState('Inputs')

  useEffect(()=>{
    if(localStorage.getItem('phone')){
      set_active_page('Rewards')
    }
  },[])
  return (
    <div className='flex flex-col w-full max-w-md p-4 mx-auto bg-white rounded-lg shadow-lg'>
      
      {
        active_page === 'Inputs' && <Inputs set_active_page={set_active_page} />
      }
      {
        active_page === 'Progress' && <AnimatedProgressBar set_active_page={set_active_page} />
      }
      {
        active_page === 'Rewards' && <Rewards  />
      }
      {/* Phone Number Input */}
      

      {/* Submit Button */}
    </div>
  );
};

export default PhoneForm;
