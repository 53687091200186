import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";

const SimCard = ({setActiveComponent}) => {
  const [submit_btn_content, set_submit_btn_content] = useState('Activate')
  const navigate = useNavigate()
  const {
    addItem,
    removeItem,
    updateItemQuantity,
    items,
    isEmpty,
    totalItems,
    cartTotal,
    emptyCart,
  } = useCart()
  


  const hadnleAddItem = ()=>{
    try{
        addItem({
            id: 4,
            name: 'EeZee 150GB 5G',
            price_stock: 108,
            price: 10.8,
            img: '/eeZee.png',
        })
    }catch(e){
        console.log(e)
    }
}
  return (
    <div className="w-full mx-auto mt-6 overflow-hidden bg-white rounded-lg shadow-md">
      <div onClick={()=>setActiveComponent('Choose')} className="flex items-center p-4">
        <div className="flex items-center justify-center w-10 h-10 rounded-full shadow">
        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M21 11L6.414 11 11.707 5.707 10.293 4.293 2.586 12 10.293 19.707 11.707 18.293 6.414 13 21 13z"></path></svg>
        </div>
        <span className="ml-2 text-lg font-semibold">Back</span>
      </div>
      <div className="p-4">
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-xl font-bold text-gray-900">eeZee</h1>
            <h2 className="mt-2 text-4xl font-bold text-gray-900">150GB</h2>
          </div>
        </div>
        <div className="mt-6">
          <h3 className="text-lg font-semibold text-gray-700">Benefits</h3>
          <ul className="mt-4 space-y-3">
            <li className="flex items-center text-gray-600">
              <span className="mr-2">
                <svg className="w-6 h-6 text-pink-500" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm1-13h-2v6h2V7zm0 8h-2v2h2v-2z"></path>
                </svg>
              </span>
              150GB 5G Internet
            </li>
            <li className="flex items-center text-gray-600">
              <span className="mr-2">
                <svg className="w-6 h-6 text-pink-500" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm1-13h-2v6h2V7zm0 8h-2v2h2v-2z"></path>
                </svg>
              </span>
              1500 Local Minutes
            </li>
            <li className="flex items-center text-gray-600">
              <span className="mr-2">
                <svg className="w-6 h-6 text-pink-500" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm1-13h-2v6h2V7zm0 8h-2v2h2v-2z"></path>
                </svg>
              </span>
              Unlimited Social Media
            </li>
            <li className="flex items-center text-gray-600">
              <span className="mr-2">
                <svg className="w-6 h-6 text-pink-500" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm1-13h-2v6h2V7zm0 8h-2v2h2v-2z"></path>
                </svg>
              </span>
              Vacant
            </li>
          </ul>
        </div>
        <div className="flex flex-col w-full bg-white rounded-lg">

          <div className="flex items-end justify-between p-2">
            <div className=""></div>
            <div
              className={`relative px-2 py-2 rounded-lg bg-pink-500 text-white`}
            >
              <div className="z-40 flex items-center">

                <span className="z-40 font-semibold ">
                  1150 <span className="text-xs ">points</span>
                </span>
              </div>
              <div
                className={`before:border-t-pink-500 absolute bottom-0 right-0 w-0 h-0 before:content-[''] before:absolute before:-bottom-[8px] before:right-[0px] before:border-t-[20px] before:border-l-[20px] before:border-transparent `}
              ></div>
            </div>
          </div>
          <div className="w-full px-2">
            <div className="relative w-full h-2 overflow-hidden rounded">
              <div
                className={`absolute inset-0 h-full bg-duotone-stripes animate-move-right-slow`}
              ></div>
            </div>
          </div>

        </div>
          
        <div className="mt-6">
          <p className="text-sm text-gray-900 line-through">KD 108.000 / 1 Year</p>
        </div>
        <div className="">
          <p className="text-lg font-bold text-gray-900">KD 10.800 / 1 Year</p>
        </div>
        <div className="mt-6">
          <button onClick={()=>{set_submit_btn_content(<div className="inputLoader"></div>);hadnleAddItem();setTimeout(()=>{navigate("/checkout_n")},3000)}} className="w-full py-2 font-semibold text-white transition-colors bg-pink-500 rounded-lg hover:bg-pink-600">
            {submit_btn_content}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SimCard;
