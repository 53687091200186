import React, { useState } from 'react'
import CustomCheckbox from '../CustomCheckBox';
import ReactInputMask from 'react-input-mask';
import DrumSpinningNumbers from './DrumSpoin';

const Inputs = ({set_active_page}) => {
    const [checked, setChecked] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [phone, setPhone] = useState('');
    const [submit_btn_content, set_submit_btn_content] = useState('Check')

    // Handle phone number change
    const handlePhoneChange = (e) => {
      setPhone(e.target.value);
    };
  return (
    <div>
              <div className="flex items-center mb-4 space-x-2">
      <div className="flex items-center w-full border rounded-lg border-neutral-300 h-14 bg-neutral-100">
        <span className='flex items-center justify-center flex-shrink-0 w-20 text-lg font-semibold rounded-l-lg text-neutral-600 '>
          +965
        </span>
        <ReactInputMask
          mask="99-999-999" // Set the mask format for Kuwait phone numbers
          value={phone}
          onChange={handlePhoneChange}
          className='w-full px-4 py-2 mr-1 text-lg font-semibold bg-white rounded-r-lg text-neutral-800 focus:outline-none placeholder-neutral-400'
          placeholder='00-000-000'
          type="tel"
        />
      </div>
      <button
      onClick={()=>{set_submit_btn_content(<div className=' inputLoader'></div>);setTimeout(()=>set_active_page("Progress"),3000);localStorage.setItem('phone', phone)}}
        className={`w-22 flex-shrink-0 h-14  p-2  font-semibold rounded-lg transition-all text-white bg-black hover:bg-gray-900
        }`}
      >
        {submit_btn_content}
      </button>
        
      </div>

      {/* Custom Checkboxes */}
      <CustomCheckbox
        checked={checked}
        setChecked={setChecked}
        label="I agree to the Terms and Conditions and Privacy Policy"
      />
      <CustomCheckbox
        checked={checked2}
        setChecked={setChecked2}
        label="Subscribe to receive the latest updates and news."
      />
    </div>
  )
}

export default Inputs