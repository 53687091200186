import React from 'react';
import { FaMobileAlt, FaWifi, FaSimCard, FaPhone } from 'react-icons/fa';

const products = [
  {
    name: 'Prepaid Plans',
    description: 'Affordable and flexible prepaid mobile plans for everyone.',
    icon: <FaSimCard className="w-8 h-8 text-white" />,
    image: '/plans/image 14.png',
  },
  {
    name: 'Postpaid Plans',
    description: 'Get more with our exclusive postpaid mobile plans.',
    icon: <FaPhone className="w-8 h-8 text-white" />,
    image: '/plans/image 15.png',
  },
  {
    name: 'Broadband Packages',
    description: 'High-speed internet for your home and office needs.',
    icon: <FaWifi className="w-8 h-8 text-white" />,
    image: '/plans/image 16.png',
  },
  {
    name: 'Mobile Devices',
    description: 'Latest smartphones and tablets with great offers.',
    icon: <FaMobileAlt className="w-8 h-8 text-white" />,
    image: '/plans/image 17.png',
  },
  {
    name: 'Zain TV',
    description: 'Enjoy high-quality entertainment with Zain TV.',
    icon: <FaWifi className="w-8 h-8 text-white" />,
    image: '/plans/image 18.png',
  },
];

const ZainProducts = () => {
  return (
    <div className="p-4">
      <h2 className="mb-4 text-2xl font-bold text-gray-900">Products</h2>
      <div className="flex space-x-4 overflow-x-scroll rounded-lg">
        {products.map((product, index) => (
          <div
            key={index}
            className="min-w-[200px] aspect-square relative   flex flex-col items-center"
          >
            <img
              src={product.image}
              alt={product.name}
              className="absolute object-cover w-full h-full rounded-lg shadow-md "
            />
            <div className="absolute flex flex-col text-start top-2 left-2">
            <div className="flex items-start mb-4">
            <div className="mb-2 text-white">{product.icon}</div>
              
            <h3 className="ml-2 text-lg font-semibold leading-5 text-white">{product.name}</h3>
            </div>
            <p className="text-sm text-white t">{product.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ZainProducts;
