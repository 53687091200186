import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa'; // Import the checkmark icon from React Icons

const CustomCheckbox = ({ label, checked, setChecked }) => {


  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  return (
    <label  className="flex items-center mb-3 cursor-pointer">
      <input
      onClick={handleCheckboxChange}
        type="checkbox"
        checked={checked}
        onChange={handleCheckboxChange}
        className="hidden" // Hide the default checkbox
      />
      <span
         // Handle click on the custom checkbox
        className={`w-5 h-5 border-2 border-black flex justify-center items-center transition-colors ${
          checked ? 'bg-black text-white' : 'bg-white'
        }`}
      >
        {checked && <FaCheck className="w-3 h-3" />} {/* Show the check icon when checked */}
      </span>
      <span className="ml-2 text-xs">{label}</span> {/* Text label */}
    </label>
  );
};

export default CustomCheckbox;
