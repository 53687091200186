import React from 'react'
import StatusMessages from '../../Pages/Loading/StatusMessage'
import Footer from '../../Footer'
import Header from '../../Header'
const Loading = () => {
  return (
    <>
    <Header />
    <div className='flex flex-col items-center w-full my-52 '>
      <img
        className="w-36 "
        src="/assets/img/document-sign.png"
        alt=""
        />
      <div className="mt-6 loader"></div>
    <StatusMessages />
    </div>
    <Footer />
        </>
  )
}

export default Loading