import React from 'react'
import { Link } from 'react-router-dom'

const Choose = ({setActiveComponent}) => {
  return (
    <div className='flex flex-col w-full mt-6 gap-y-5'>
        <div onClick={()=>setActiveComponent('Sim')} className="w-full">
            <img src="/redeem/zain.png" alt="" />
        </div>
        <a href='https://kw.delliveroo.com/?p=123123123&promo=90' className="w-full">
            <img src="/redeem/deliveroo.png" alt="" />
        </a>
        <a href='https://pettzoone.com/?p=123123123' className="w-full">
            <img src="/redeem/petzone.png" alt="" />
        </a>
        <Link to={'/shop/0'}>   
            <img src="/redeem/shop_ps.png" alt="" />
        </Link>
        <Link to={'/shop/1'}>
            <img src="/redeem/shop_ip.png" alt="" />
            
        </Link>
        <Link to={'/shop/2'}>
            <img src="/redeem/shop_sm.png" alt="" />
            
        </Link>
    </div>
  )
}

export default Choose