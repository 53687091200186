import React, { useState } from 'react';

const faqData = [
  {
    question: 'Is linking my phone number secure?',
    answer:
      'Yes, your phone number is protected by strict privacy standards. We use it solely for account verification and security purposes, ensuring your personal information remains safe.',
  },
  {
    question: 'Why do I need to link my phone number to use this service?',
    answer:
      'Linking your phone number allows us to securely verify your identity and provide personalized offers and bonuses that are exclusive to you.',
  },
  {
    question: 'Is this service trustworthy?',
    answer:
      'Absolutely. We prioritize transparency and user security. Our service follows industry-standard encryption and privacy practices to ensure your data is always protected.',
  },
  {
    question: 'Are the bonuses and perks real?',
    answer:
      'Yes, all bonuses and perks offered through our service are real and redeemable. We partner with reputable brands and businesses to bring you exclusive offers.',
  },
  {
    question: 'Will my phone number be sold or used for spam?',
    answer:
      'No, we do not share or sell your phone number to third parties. Your contact information is used strictly for account management and notifications relevant to your perks.',
  },
  {
    question: 'How can I be sure that the perks I receive are legitimate?',
    answer:
      'Every perk you receive is verified and provided by our trusted partners. We ensure that all offers are authentic and can be claimed directly through our platform.',
  },
  {
    question: 'What happens if I don’t receive my bonuses or perks?',
    answer:
      'If you experience any issues with receiving your bonuses, our support team is here to help. Contact us, and we’ll ensure you get the perks you’re entitled to.',
  },
];

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="max-w-xl p-4 mx-auto">
      {faqData.map((faq, index) => (
        <div key={index} className="py-3 border-b border-gray-300">
          <button
            onClick={() => toggleFAQ(index)}
            className="flex items-center justify-between w-full text-left text-gray-800 focus:outline-none"
          >
            <span className="font-semibold ">{faq.question}</span>
            <span className="text-gray-500">
              {activeIndex === index ? '-' : '+'}
            </span>
          </button>
          {activeIndex === index && (
            <p className="mt-2 text-sm text-gray-600 transition-all duration-300 ease-in-out">
              {faq.answer}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default FaqSection;
