import CEOMessage from "./components/CEO";
import FaqSection from "./components/Faq";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import PhoneForm from "./components/PhoneForm";
import PoppingBoxes from "./components/PoppingBoxed";
import ZainProducts from "./components/Products";
import AnimatedProgressBar from "./components/ProgressBar";
import Steps from "./components/Steps";
import {PromoProvider} from './providers/Init'

function App() {
  return (
    <PromoProvider>
      
    <div className="flex flex-col w-full min-h-lvh">
      {/* <PoppingBoxes /> */}
      <Header />
      <HeroSection />
      <CEOMessage />
      <Steps />
      <PhoneForm />
  
      <ZainProducts />
      <FaqSection />
      <Footer />
    </div>
    </PromoProvider>
  );
}

export default App;
